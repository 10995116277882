import * as yup from "yup";
import AlertError from '../Error/alert'
const validationsForm =  yup.object().shape({
  new_name: yup.string().required("Name is Required"),
  new_iban: yup.string().required("IBAN is Required")
  .matches( /^(EG)(\d{2})(\d{3})(\d{22})$/, 'Invalid Egyptian IBAN format, eg EG000000000000000000000000000 '),
  new_mobilenumber: 
  yup.string()
  .required('Mobile Number is required')
  .matches(/^\d+$/, 'Mobile Number must only contain numbers')
  .length(11, 'Mobile Number must be exactly 11 number'),
  
  new_nationalid: yup.string().required("Required")
  .length(14, 'National ID must be exactly 14 digits')
  .matches(/^\d+$/, 'National ID must only contain digits')
  // .test('nationalId-exists', 'National ID does not exist', async function (value) {
  //   if (!value) return true; // Skip validation if the field is empty

  //   try {
  //     const response = await axios.get(`YOUR_API_ENDPOINT/${value}`);
  //     const exists = response.data.exists;
  //     return exists;
  //   } catch (error) {
  //     console.error('API Error:', error);
  //     return false;
  //   }
  // })
  ,
  gtoken: yup.string().required(<AlertError message="Captcha is Required"/>),
  countsoc: yup.number()
  .min(1, <AlertError message="Add at least 1 Scope of Work" />)
  .required(<AlertError message="Add at least 1 Scope of Work" />),

  front_id_image:yup.object().required(<AlertError message="This Attachment is Required" />)
  .shape({
    data: yup.string(),
  })
  .test('fileSize', <AlertError message='Attachment size exceeds 4MB'/>, (value) => {
    if (!value) return true; // Skip validation if no file is selected
    return value.size <= 4 * 1024 * 1024; // 2MB in bytes
  }),
 
  back_id_image:yup.object().required(<AlertError message="This Attachment is Required"/>)
  .shape({
    data: yup.string(),
  })
  .test('fileSize', <AlertError message='Attachment size exceeds 4MB'/>, (value) => {
    if (!value) return true; // Skip validation if no file is selected
    return value.size <= 4 * 1024 * 1024; // 2MB in bytes
  }),
  iban_image:yup.mixed().nullable()
  .test('fileSize', <AlertError message='Attachment size exceeds 4MB'/>, (value) => {
    if (!value) return true; // Skip validation if no file is selected
    return value.size <= 4 * 1024 * 1024; // 2MB in bytes
  }),
 });

export default validationsForm;

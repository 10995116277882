import React from 'react';
import JobAppForm from './components/MainForm';  
import './App.css';
// import NotFound from './components/Error/NotFound'

import { useLocation, Routes ,Route} from 'react-router-dom';
function App()  {
  return (
          <>
          <Routes onUpdate={() => window.scrollTo(0, 0)}>
                <Route path="/" element={<JobAppForm/>}/>
          </Routes>
          <footer>
          <div>© <a href="https://rowad-rme.com">Rowad Modern Engineering</a> </div>
          <div>All rights reserved. {new Date().getFullYear() }</div>
          </footer>
          </>
 
  );
};
export default App;
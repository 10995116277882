import React from 'react';
import validationsForm from "./ValidationSchema";
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import {TableHead ,TableRow ,TableCell ,TableContainer,Table , TableBody,Box, Modal, Fade,Backdrop,
  Typography, TextField, Button, MenuItem, Grid,IconButton   } from '@mui/material';
import { Edit, Delete, ViewDaySharp } from '@material-ui/icons';
function UniversityTable({ data, handleEdit, handleDelete }) {
    const [open, setOpen] = React.useState(false);
    // const [editData, setEditData] = React.useState({});
    const formikEducation= useFormik({
      initialValues:{
        id: 0,
        new_workpackage: {id:'',name:'',name_ar:''},
        new_commodity: {id:'',name:'',name_ar:''},
      },
      validationSchema: validationsForm,
      
      onSubmit: values => { 
        const{new_from,new_to,...rest}=values
        handleEdit({
          ...rest
        },data,"Scope of Work");
        handleClose();
     },
    });

 
    const handleOpen = (rowData) => {
      setOpen(true);
 
      const {new_from,new_to,...rest}=rowData
      formikEducation.setValues({"new_from":dayjs(new_from),"new_to":dayjs(new_to),...rest});
    };
  
    const handleClose = () => {
      setOpen(false);
      formikEducation.setTouched({});
    };
 
  
    return (
      <TableContainer>
        <Table>
          {data.length>0?( //Appear Table Header if theres at 1 data
          <TableHead>
            <TableRow>
              <TableCell>Commodity</TableCell>
              <TableCell>Workpackage</TableCell>
  
            </TableRow>
          </TableHead>
          ):<></>}
          <TableBody>
            
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.new_commodity.name+' '+row.new_commodity.name_ar}</TableCell>
                <TableCell>{row.new_workpackage.name+' '+row.new_commodity.name_ar}</TableCell>
                <TableCell>
      
                  <IconButton onClick={() => handleDelete(row.id,"Scope of Work")}>
                  <Delete />
                </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
        <Fade in={open}>
          <Box className='popupaddedit'>
          <Typography id="transition-modal-title" variant="h4" component="h2">
            Edit University 
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 2 }}>
          {/* <Grid container spacing={2}>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                variant="outlined"
                                name="new_commodity"
                                label="University"
                                value={formikEducation.values.new_commodity}
                                onChange={formikEducation.handleChange}
                                onBlur={formikEducation.handleBlur}
                                helperText={formikEducation.touched.new_commodity && formikEducation.errors.new_commodity}
                                error={ formikEducation.touched.new_commodity  && Boolean(formikEducation.errors.new_commodity)}
                                placeholder="University"
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_workpackage"
                                label="Faculty"
                                value={formikEducation.values.new_workpackage}
                                onChange={formikEducation.handleChange}
                                onBlur={formikEducation.handleBlur}
                                helperText={formikEducation.touched.new_workpackage && formikEducation.errors.new_workpackage}
                                error={ formikEducation.touched.new_workpackage  && Boolean(formikEducation.errors.new_workpackage)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                            <LocalizationProvider  dateAdapter={AdapterDayjs} >
                              <DemoContainer  components={['DatePicker']} >
                                <DatePicker
                                label="From"
                                className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                                value={ formikEducation.values.new_from || null}
                                onChange={(date) => formikEducation.setFieldValue('new_from',  date )}
                                format="YYYY-MM-DD"
                                slotProps={{
                                  textField: {
                                    required:true,
                                    error: formikEducation.touched.new_from  && Boolean(formikEducation.errors.new_from),
                                    helperText:formikEducation.touched.new_from && formikEducation.errors.new_from
                                  },
                                  }}
                                  />
                                </DemoContainer>
                            </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                            <LocalizationProvider  dateAdapter={AdapterDayjs} >
                              <DemoContainer  components={['DatePicker']} >
                                <DatePicker
                                label="To"
                                className="css-17vbkzs-MuiFormControl-root-MuiTextField-root"
                                // format='YYYY-MM-DD'
                                value={ formikEducation.values.new_to || null}
                                onChange={(date) => formikEducation.setFieldValue('new_to',  date )}
                                format="YYYY-MM-DD"
                                slotProps={{
                                  textField: {
                                    required:true,
                                    error: formikEducation.touched.new_to  && Boolean(formikEducation.errors.new_to),
                                    helperText:formikEducation.touched.new_to && formikEducation.errors.new_to
                                  },
                                  }}
                                  />
                                </DemoContainer>
                            </LocalizationProvider>
 
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField required
                                  name="new_degree"
                                  select
                                  label="Degree"
                                  value={formikEducation.values.new_degree}
                                  onChange={formikEducation.handleChange}
                                  fullWidth
                                  margin="normal"
                                  onBlur={formikEducation.handleBlur}
                                  helperText={formikEducation.touched.new_degree && formikEducation.errors.new_degree}
                                  error={ formikEducation.touched.new_degree  && Boolean(formikEducation.errors.new_degree)}
                                  id="new_degree"
                                >{
                                  [
                                    {value:"Bachelor's degree",name:"Bachelor's degree"},
                                    {value:"Master's degree",name:"Master's degree"},
                                    {value:"Doctoral degree",name:"Doctoral degree"}
                                  ].map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </TextField>   
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_graderating"
                                label="Grade/Rating"
                                value={formikEducation.values.new_graderating}
                                onChange={formikEducation.handleChange}
                                onBlur={formikEducation.handleBlur}
                                helperText={formikEducation.touched.new_graderating && formikEducation.errors.new_graderating}
                                error={ formikEducation.touched.new_graderating  && Boolean(formikEducation.errors.new_graderating)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_major"
                                label="Major"
                                value={formikEducation.values.new_major}
                                onChange={formikEducation.handleChange}
                                onBlur={formikEducation.handleBlur}
                                helperText={formikEducation.touched.new_major && formikEducation.errors.new_major}
                                error={ formikEducation.touched.new_major  && Boolean(formikEducation.errors.new_major)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <TextField 
                                type="text"
                                name="new_minor"
                                label="Minor"
                                value={formikEducation.values.new_minor}
                                onChange={formikEducation.handleChange}
                                onBlur={formikEducation.handleBlur}
                                helperText={formikEducation.touched.new_minor && formikEducation.errors.new_minor}
                                error={ formikEducation.touched.new_minor  && Boolean(formikEducation.errors.new_minor)}
                                margin="normal"
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <Button variant="contained"  color="success" type="button" onClick={formikEducation.handleSubmit}>
                                Save
                              </Button>
                            </Grid>
                          </Grid> */}
            </Typography>
          </Box>
        </Fade>
      </Modal>

      </TableContainer>
    );
  }
  export default UniversityTable;
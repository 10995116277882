import * as yup from "yup";
const validationsForm =  yup.object().shape({
  new_commodity: yup.object().shape({
    id: yup.string().required("Required"),
  }),
  new_workpackage:yup.object().shape({
    id: yup.string().required("Required"),
  }),
 
});

export default validationsForm;

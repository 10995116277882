import React from 'react'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
export default function alert({message}) {
  return (
    <Stack sx={{ width: '100%' }} spacing={5}>
        <Alert severity="error">
            <AlertTitle>Error: {message}</AlertTitle>
        </Alert>
    </Stack>
  )
}

import React from 'react';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import LoadingButton from '@mui/lab/LoadingButton';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Autocomplete, Box, Modal, Fade,Backdrop,
  Typography, TextField, Button,Grid,Container,
  Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper
} from '@mui/material';
import './1.General/mobilenumber.style.css'
import GeneralvalidationsForm from './1.General/ValidationSchema'
import { useFormik} from 'formik';
import UniversityTable from './3.Edu/Edu/UniversityTable';
import EduvalidationsForm  from './3.Edu/Edu/ValidationSchema'
const EmploymentForm = () => {
  const recaptchaRef = React.createRef();
  const MySwal = withReactContent(Swal);
  const [activeStep, setActiveStep] = React.useState(0);
  const [Commodities, setCommodities] = React.useState([]);
  const [AllWorkpackages, setAllWorkpackages] = React.useState([]);
  const [FilterWorkpackages, setFilterWorkpackages] = React.useState([]);
  const [LoadingAPI,setLoadingAPI]= React.useState(false);
 
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
 
  // General
  const formikGeneral = useFormik({
    initialValues: {
      new_name: '',
      new_nationalid:'',
      new_mobilenumber:'',
      countsoc:0,
      front_id_image:null,
      back_id_image:null,
      gtoken:null,
      new_iban:null,
    },
    validationSchema: GeneralvalidationsForm,
    onSubmit: values => {
      const {new_nationalid,countsoc,front_id_image,back_id_image,iban_image,...rest}=values
      const ext1= formikGeneral.values.new_name+" National ID Front."+front_id_image.ext
      const ext2= formikGeneral.values.new_name+" National ID Back."+back_id_image.ext
      const attachment = [
        {
          isdocument: true,
          mimetype: front_id_image.mimetype,
          documentbody: front_id_image.data.split('base64,')[1],
          filename: ext1
        },
        {
          isdocument: true,
          mimetype: back_id_image.mimetype,
          documentbody: back_id_image.data.split('base64,')[1],
          filename: ext2
        },
        iban_image && {
          isdocument: true,
          mimetype: iban_image.mimetype,
          documentbody: iban_image.data.split('base64,')[1],
          filename: formikGeneral.values.new_name + ' IBAN.' + iban_image.ext
        }
      ].filter(Boolean);;
      axios.post(`${process.env.REACT_APP_Back_URL}/vendors`,{
        "selfex":{
          "new_nationalid":new_nationalid.toString(),
          ...rest,
        },
        attachment,
        listsocdata
      }
        ).then(resp=>{
          setLoadingAPI(false)
          recaptchaRef.current?.reset();
          formikGeneral.setFieldValue("gtoken",null)
        if(resp.status==200){
          MySwal.fire({
            // position: 'top-end',
            icon: 'success',
            title: 'Submitted Successfully',
            showConfirmButton: false,
            timer: 1500
          }).then(
          setTimeout(()=> {
           window.location.reload();
           window.scrollTo(0, 0);
          },1500)
          ) 
        }
        }
        )      
        .catch(function (error) {  
          setLoadingAPI(false)
          recaptchaRef.current?.reset(); 
          MySwal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message
 
          });
         })

         formikGeneral.resetForm();
         formikGeneral.setFieldValue("new_iban",null)
         formikGeneral.setFieldValue("new_iban",null)
    },
  });
  const rows = [
  createData(
  <>
    <span style={{color:'red',paddingRight:'5px',fontSize:'20px'}}>*</span>National ID ( Front ) بطاقة القومي ( اماميه ) 
    {formikGeneral.touched.front_id_image && formikGeneral.errors.front_id_image?
                       <p>{formikGeneral.errors.front_id_image}</p>:<></>
                    }
   
  </>,     
    <input
    id="file"
    name="front_id_image"
    type="file"
    onChange={(e) => {
      if(e.target.files.length>0){
      const data=new FileReader()
      data.addEventListener('load',()=>{
        formikGeneral.setFieldValue('front_id_image', { size:e.target.files[0].size ,ext:e.target.files[0].name.split('.')[1], mimetype:e.target.files[0].type, data: data.result });
      })
      data.readAsDataURL(e.target.files[0])
    }
    else{
      formikGeneral.setFieldValue('front_id_image', null );
    }
    }}
  />,
  
//<iframe src={formikGeneral.values.front_id_image?.data} width="70%" height={'50%'}/> 
       <img width={"25%"} src={formikGeneral.values.front_id_image?.data} />
  ),
  createData(
  <>
    <span style={{color:'red',paddingRight:'5px',fontSize:'20px'}}>*</span>National ID ( Back ) بطاقة القومي ( الخلفية )
    {formikGeneral.touched.back_id_image && formikGeneral.errors.back_id_image?
                       <p>{formikGeneral.errors.back_id_image}</p>:<></>
                    }
  </>,     
  <input
  id="file2"
  name="back_id_image"
  type="file"
  onChange={(e) => {
    if(e.target.files.length>0){
    const data=new FileReader()
    data.addEventListener('load',()=>{
      formikGeneral.setFieldValue('back_id_image', { size:e.target.files[0].size, ext:e.target.files[0].name.split('.')[1], mimetype:e.target.files[0].type, data: data.result });
    })
    data.readAsDataURL(e.target.files[0])
  }
  else{
    formikGeneral.setFieldValue('back_id_image', null );
  }
  }}
/>,
   <img width={"25%"} src={formikGeneral.values.back_id_image?.data} />


),
  createData(
  <>
    IBAN  رقم الحساب المصرفي الدولي
    <p>{formikGeneral.errors.iban_image}</p>
  </>,     
  <input
  id="file3"
  name="iban_image"
  type="file"
  onChange={(e) => {
    if(e.target.files.length>0){
    const data=new FileReader()
    data.addEventListener('load',()=>{
      formikGeneral.setFieldValue('iban_image', {  size:e.target.files[0].size,ext:e.target.files[0].name.split('.')[1], mimetype:e.target.files[0].type, data: data.result });
    })
    data.readAsDataURL(e.target.files[0])
  }
  else{
    formikGeneral.setFieldValue('iban_image', null );
  }
  }}
  />,
  <img width={"25%"} src={formikGeneral.values.iban_image?.data} />


  ),
  ];
   // Scope of Work
  const formikSOC= useFormik({
    initialValues: {
      new_workpackage: {id:'',name:'',name_ar:''},
      new_commodity: {id:'',name:'',name_ar:''},
    },
    validationSchema: EduvalidationsForm,
    onSubmit: values => {
      // const{new_from,new_to,...rest}=values 
      handleAdd(values,"Scope of Work");
      handleCloseEdu()
   },

 
  });
  const [listsocdata, setlistsocdata] = React.useState([]);
  const [openAddEdu, setOpenAddEdu] = React.useState(false);
  const handleOpenEdu = () => setOpenAddEdu(true);
  const handleCloseEdu = () =>{
    setOpenAddEdu(false);
    formikSOC.resetForm()
  }
 
 

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  React.useEffect(() => {
    let endpoints = [
      `${process.env.REACT_APP_Back_URL}/vendors/commodities`,
      `${process.env.REACT_APP_Back_URL}/vendors/workpackages`,
 
    ];
    
    Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
    .then((data) => 
    {
      data.forEach((resp, index) => {
        if(index===0){
          let arr= resp.data.sort((a,b) => (a.new_name.toUpperCase() > b.new_name.toUpperCase()) ? 1 : ((b.new_name.toUpperCase() > a.new_name.toUpperCase()) ? -1 : 0))
          setCommodities(arr.map((row) =>{
           return { id : row.new_commodityid, name : row.new_name.replace(",", ""), name_ar:row.new_commodityar}
          }))
        }
 
        if(index===1){
          let arr= resp.data.sort((a,b) => (a.new_name.toUpperCase() > b.new_name.toUpperCase()) ? 1 : ((b.new_name.toUpperCase() > a.new_name.toUpperCase()) ? -1 : 0))
          setAllWorkpackages(arr.map((row) =>{
           return { id : row.new_workpackageid, name : row.new_name.replace(",", ""),commodityid:row._new_commodityid_value,name_ar:row.new_workpackagear}
          }))
          setFilterWorkpackages(arr.map((row) =>{
            return { id : row.new_workpackageid, name : row.new_name.replace(",", ""),commodityid:row._new_commodityid_value,name_ar:row.new_workpackagear}
           }))
        }
        if(index===2){
          let arr= resp.data.sort((a,b) => (a.new_country.toUpperCase() > b.new_country.toUpperCase()) ? 1 : ((b.new_country.toUpperCase() > a.new_country.toUpperCase()) ? -1 : 0))
          setPlaceOfBirth(arr.map((row) =>{
            return { id : row.new_countriesid, name : row.new_country}
          }))
          }
        if(index===3){
          let arr= resp.data
            setCity(arr.map((row) =>{
              return { id : row.new_citiesid, name : row.new_city}
            }))
          }
      })
    }
    );
  },[]);
  const handleAdd = (values,tablename) => {
    if(tablename==="Scope of Work"){
   
      const newId = listsocdata.length + 1;
      const newData = { id: newId,...values };
      setlistsocdata([...listsocdata,newData]);
      formikGeneral.setFieldValue("countsoc",listsocdata.length + 1)
    }
  };
  const handleEdit = (editedData,currentdata,tablename) => {
    const updatedData = currentdata.map((row) => {
      if (row.id === editedData.id) {
        return { ...row, ...editedData };
      }
      return row;
    });
    if(tablename==="Scope of Work"){
      setlistsocdata(updatedData);
    }  
  };          
  const handleDelete = (id,tablename) => {
    
        if(tablename==="Scope of Work"){
          const updatedData = listsocdata.filter((row) => row.id !== id);
          setlistsocdata(updatedData);
          formikGeneral.setFieldValue("countsoc",listsocdata.length - 1)
    }  
  };
  
  const handleNextSubmit = () => {        
      switch (activeStep) {
 
        case 0:  formikGeneral.handleSubmit();   break;
        case 1:    
        if(listsocdata.length>0)  {
          TableSubmit();
        }
          else{
            MySwal.fire({
              icon: "warning",
              title: "Oops...",
              text: "Please enter at least 1 Scope of Work"
  
            });
          }
          break;
        case 2:
          if(formikBackgroundVerif.isValid)  {
        formikBackgroundVerif.handleSubmit(); 
          }
          else{
            MySwal.fire({
              icon: "warning",
              title: "Oops...",
              text: "Please fill all the required fields"
   
            });
          }
        break
        case 3:  formikAdditionalInfo.handleSubmit(); break;
      }                        
  };
 
  return (
      <Container >     
        <Box sx={{ bgcolor: 'rgb(255 255 255)', textAlign: 'left', padding: '30px' }}>
        <Grid container spacing={2}>
           <Grid item xs={11} sm={4}>
              <img src='https://rowad-rme.com/wp-content/uploads/Rowad-Logo.png' height={70}/>
            </Grid>
            <Grid item xs={11} sm={8}>
            <div className="wrapper">
              <Typography variant="h6" gutterBottom fontWeight="bold">
                 Self Execution Form
              </Typography>
             </div>
            </Grid>
        </Grid>
          <form noValidate autoComplete="off">
              {activeStep === 0 && (  
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={11} sm={4}>
                      <h3><span style={{color:'red',paddingRight:'5px'}}>*</span>General معلومات اساسية</h3>
                    </Grid>
                  </Grid>                
                  <Grid container spacing={5}>
                      <Grid item xs={11} sm={5}>
                      <TextField 
                            required
                            name="new_name"
                            id="new_name"
                            // autoFocus 
                            helperText={formikGeneral.touched.new_name && formikGeneral.errors.new_name}
                            error={ formikGeneral.touched.new_name  && Boolean(formikGeneral.errors.new_name)}
                            label="Name  الاسم"
                            value={formikGeneral.values.new_name}
                            onChange={formikGeneral.handleChange}
                            onBlur={formikGeneral.handleBlur}
                            fullWidth
                            margin="normal"
                            />
                      </Grid>
                      <Grid item xs={11} sm={5}>
                        <TextField 
                          required
                          name="new_nationalid"
                          id="new_nationalid"
                          label="National ID بطاقه الرقم الرقمي "
                          inputProps={{
                            maxLength: 14,
                            type: 'number',
                          }}
                          value={formikGeneral.values.new_nationalid}
                          onChange={formikGeneral.handleChange}
                          onBlur={formikGeneral.handleBlur}
                          helperText={formikGeneral.touched.new_nationalid && formikGeneral.errors.new_nationalid}
                          error={ formikGeneral.touched.new_nationalid  && Boolean(formikGeneral.errors.new_nationalid)}
                          fullWidth
                          margin="normal"
                          // type='number'
                          onKeyDown={(e) => {
                            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                              e.preventDefault()
                            }
                          }}
                        />
                      </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                      <Grid item xs={11} sm={5}>
                        <TextField 
                            required
                            name="new_mobilenumber"
                            id="new_mobilenumber"
                            // autoFocus 
                            helperText={formikGeneral.touched.new_mobilenumber && formikGeneral.errors.new_mobilenumber}
                            error={ formikGeneral.touched.new_mobilenumber  && Boolean(formikGeneral.errors.new_mobilenumber)}
                            label="Mobile موبيل"
                            value={formikGeneral.values.new_mobilenumber}
                            onChange={formikGeneral.handleChange}
                            onBlur={formikGeneral.handleBlur}
                            // type="number"
                          
                            onKeyDown={(e) => {
                              if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                                e.preventDefault()
                              }
                            }}
                            fullWidth
                            margin="normal"
                        />
                        {/* <PhoneInput
                          specialLabel="Mobile موبيل"
                          onlyCountries={['eg']}
                          isValid={(value, country) => {
                            if (value.match(/12345/)) {
                              return 'Invalid value: '+value+', '+country.name;
                            } else if (value.match(/1234/)) {
                              return false;
                            } else {
                              return true;
                            }
                          }}
                          disableDropdown
                          disableCountryCode
                          placeholder= "01X XXX XXX XX" 
                          country={'eg'}
                          value={formikGeneral.values.new_mobilenumber}
                          inputStyle={{
                            borderColor: (formikGeneral.touched.new_mobilenumber && formikGeneral.errors.new_mobilenumber) && "red"
                          }}
                          onChange={(value) => {
 
                            if (value.length < 11) {
                            console.log(value)
                            formikGeneral.setFieldValue('new_mobilenumber',value);
                            formikGeneral.handleChange('new_mobilenumber')(value);
                          }
                          }}
                        /> */}
                      </Grid> 
                      <Grid item xs={11} sm={5}>
                        <TextField 
                            required
                            name="new_iban"
                            placeholder='EGXXXXXXXXXXXXXXXXXXXXXXXXX'
                            id="new_iban"
                            // autoFocus 
                            helperText={formikGeneral.touched.new_iban && formikGeneral.errors.new_iban}
                            error={ formikGeneral.touched.new_iban  && Boolean(formikGeneral.errors.new_iban)}
                            label="IBAN  رقم الحساب المصرفي الدولي"
                            value={formikGeneral.values.new_iban}
                            onChange={formikGeneral.handleChange}
                            onBlur={formikGeneral.handleBlur}
                            fullWidth
                            margin="normal"
                        />
                      </Grid> 
                  </Grid>
                  <Grid container spacing={2}>
                      <Grid item xs={11} sm={4}>
                        <h3><span style={{color:'red',paddingRight:'5px'}}>*</span>Scope of Work مجال العمل</h3>
                      </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                  {formikGeneral.touched.countsoc && formikGeneral.errors.countsoc?
                      <Grid item xs={11} sm={4}>
                        <div className='Error'>{formikGeneral.errors.countsoc}</div> 
                      </Grid>:<></>
                    }
                  </Grid>   
                  <Button onClick={handleOpenEdu} variant="outlined">Add</Button>
                 <div>
                 <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openAddEdu}
                    onClose={handleCloseEdu}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={openAddEdu}>
                      <Box sx={style}>
                        <h2>Add Scope of Work أضف مجال العمل</h2>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={11} sm={6}>
                            <Autocomplete
                            fullWidth
                            id="combo-box-demo"
                            getOptionLabel={(option) => option.name +" "+option.name_ar }  
                            options={Commodities}
                            fullwidth
                            isOptionEqualToValue={(option,value)=>
                              option.name+" "+option.name_ar === value.name+" "+value.name_ar 
                            }
                            noOptionsText={"Not Found"}
                            renderOption={(props, option, index) => {
                              const key = `listItem-${index}-${option.id}`;
                              return (
                                <li {...props} key={key}>
                                  {option.name+" "+option.name_ar}
                                </li>
                              );
                            }}
                            value={ formikSOC.values.new_commodity }
                            onChange={(e, value) =>{
                              formikSOC.setFieldValue("new_workpackage", {id:'',name:'',name_ar:''})
                              if(value!=null){
                                formikSOC.setFieldValue("new_commodity", value)
                                setFilterWorkpackages(AllWorkpackages.filter((x)=> x.commodityid===value.id))
                              } else {
                                formikSOC.setFieldValue("new_commodity", {id:'',name:'',name_ar:''})
                              }
                            }}
                            renderInput={(params) => 
                            <TextField 
                            required
                            margin="normal"
                            fullWidth
                              {...params}      
                              name="new_commodity"
                              error={formikSOC.errors.new_commodity?.id && formikSOC.touched.new_commodity?.id}
                              helperText={formikSOC.touched.new_commodity?.id && formikSOC.errors.new_commodity?.id}
                              label="Commodity سلعة" />
                            }
                            />   
 
                            </Grid>
                            <Grid item xs={11} sm={6}>
                            <Autocomplete
                            fullWidth
                            id="combo-box-demo"
                            getOptionLabel={(option) => option.name+' '+option.name_ar }  
                            options={FilterWorkpackages}
                            fullwidth
                            isOptionEqualToValue={(option,value)=>
                              option.name+' '+option.name_ar=== value.name+' '+value.name_ar
                            }
                            noOptionsText={"Not Found"}
                            renderOption={(props, option, index) => {
                              const key = `listItem-${index}-${option.id}`;
                              return (
                                <li {...props} key={key}>
                                  {option.name+" "+option.name_ar}
                                </li>
                              );
                            }}
                            value={ formikSOC.values.new_workpackage }
                            onChange={(e, value) =>{
                              if(value!=null){
                                formikSOC.setFieldValue("new_workpackage", value)
                              } else {
                                formikSOC.setFieldValue("new_workpackage", {id:'',name:'',name_ar:''})
                              }
                            }}
                            renderInput={(params) => 
                            <TextField 
                            required
                            margin="normal"
                            fullWidth
                              {...params}      
                              name="new_workpackage"
                              error={formikSOC.errors.new_workpackage?.id && formikSOC.touched.new_workpackage?.id}
                              helperText={formikSOC.touched.new_workpackage?.id && formikSOC.errors.new_workpackage?.id}
                              label="Work Package حزمة العمل" />
                            }
                            />  
                            </Grid>
  
                            <Grid item xs={11} sm={4}>
                              <Button  margin="normal" variant="contained"  color="success" type="button" onClick={formikSOC.handleSubmit}>
                                Add
                              </Button>
                            </Grid> 
                          </Grid>
                        </Typography>
                      </Box>
                    </Fade>
                  </Modal>
                  <UniversityTable data={listsocdata} handleEdit={handleEdit} handleDelete={handleDelete}   /> 
                 </div>
                 <div>
                    {formikGeneral.touched.file && formikGeneral.errors.file && (
                      <div>{formikGeneral.errors.file}</div>
                    )}
                 </div>
                 <Grid item xs={11} sm={4}>
                        <h3><span style={{color:'red',paddingRight:'5px'}}>*</span>Attachment المرفقات</h3>
                      </Grid>
                 <div>
                    {/*      
                      <Dropzone
                      onChange={updateFiles}
                      value={files}
                      cleanFiles
                      // validator={myOwnValidation}
                      >
                        {files.map((file) => (
                            <FileMosaic key={file.id} {...file}
                            onChange={handleFileChange}
                            onDelete={removeFile} info />
                          ))}
                      </Dropzone> */}
                 </div>
                 <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">File Name</TableCell>
                          <TableCell align="left">Upload</TableCell>
                          <TableCell align="left">Preview</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="right">{row.calories}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.protein}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                 </TableContainer>
                 <Grid container spacing={2}>
                    <Grid item xs={11} sm={4}>
                    {formikGeneral.touched.gtoken && formikGeneral.errors.gtoken?
                       <p>{formikGeneral.errors.gtoken}</p>:<></>
                    }
                 </Grid>
                 </Grid> 
                 <ReCAPTCHA 
                  ref={recaptchaRef} 
                  sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                  onChange={(value) => {
                    formikGeneral.setFieldValue("gtoken", value);
                    formikGeneral.setSubmitting(false);
                  }} 
                  /> 
     
                  <LoadingButton variant="contained"
                      loading={LoadingAPI}
                  onClick={handleNextSubmit} >
                    Submit
                  </LoadingButton>
                </>
              )}
 
          </form>

        </Box>
      </Container>
  );
};

export default EmploymentForm; 